html,
body,
#root {
  height: 100%;
}

body {
  display: flex;
  flex-direction: column;
}

.App {
  text-align: center;
  background-color: #141414;
  color: #fff;
  font-family: 'Open Sans', sans-serif;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.wrapper {
  flex: 1 0 auto;
}

/*to use this button style, you must put a button html tag in your .js file*/

button,
input.button-link,
a.button-link {
  background-color: #4688fe;
  font-size: 0.8em;
  color: #fff;
  border-radius: 20px;
  padding: 2px 40px;
  text-transform: uppercase;
  font-weight: bold;
  font-family: 'Open Sans', sans-serif;
  text-decoration: none;
  border: 0;
}

a.button-link:visited {
  text-decoration: none;
}

a.text-link {
  color: #4688fe;
  font-weight: bold;
}

h1 {
  font-weight: normal;
  font-size: 2.2em;
  border-bottom: 2px solid #4688fe;
  margin-top: 0;
}

h2 {
  font-weight: normal;
  font-size: 1.3em;
  margin-top: 0;
}

.content {
  background-color: #232324;
  margin: 40px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 40px;
}

.content-text {
  text-align: left;
  font-size: 1.4em;
  font-weight: 100;
}

.content-image {
  margin: 40px;
}

.photocontent {
  background-color: #232324;
}

/*.Sibling connectors. Selects cases where these things are siblings, and only applies styling to the second
sib to the right of the + sign. This was used to "collapse margins" between text and picture divs when using display: flex*/

.content + .content,
.content + .content-image,
.content-image + .content {
  margin-left: 0;
}

/*@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}*/

/*.App-link {
  color: #61dafb;
}*/

/*@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}*/

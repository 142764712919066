.Home {
  display: flex;
}

.Home .button-link {
  background-color: #ffd43b;
  color: black;
  font-size: 1.4em;
}

.Home .Donate {
  align-self: center;
  padding-top: 75px;
}

.Sponsors {
  display: flex;
}

.contributors-list,
.Sponsors-list,
.board-list {
  list-style-type: none;
  padding: 0px;
}

.diamond {
  color: #89CFF0;
}

.star-gold {
  color: #f7eb56;
}

.star-silver {
  color: #ababab;
}

.star-bronze {
  color: #c28534;
}

.star-platinum {
  color: #e5e4e2;
}

.Sponsors .content {
  width: 50%;
}

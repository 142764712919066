.Event {
  display: flex;
  margin: 20px;
  margin-inline-start: 0;
}

.Event-date {
  border-right: 8px solid #4688fe;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-right: 25px;
}

.Event-month-day {
  font-size: 1.8em;
}

.Event-year {
  font-size: 2.5em;
}

.Event-details {
  padding-left: 25px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.Event-name {
  font-weight: bold;
  font-size: 1.6em;
}

.Event-address {
  font-size: 1.4em;
}

.Event-time {
  font-size: 1.4em;
  text-transform: lowercase;
}

.Event-button-name {
  font-size: 1.25em;
  margin: 10px 0;
}

.Header {
  background-color: #000000;
  min-height: 350px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: calc(10px + 1vw);
  color: white;
  background-image: url('../../assets/images/spoons.png');
  background-size: cover;
  position: relative;
}

.Header-logo {
  display: flex;
  width: 34%;
  margin-left: 35px;
}

.Events {
  display: flex;
}

.Events-upcoming {
  margin-left: 0;
}

.Events-calendar {
  position: sticky;
  align-self: flex-start;
  top: 0;
  margin: 40px;
}

.Events-content {
  flex-grow: 1;
}


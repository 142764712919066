.Nav {
  margin: 0;
  padding: 0.75em;
  list-style-type: none;
  position: absolute;
  right: 0;
  align-self: flex-start;
}

.Nav li {
  display: inline;
  cursor: pointer;
}

.Nav li a {
  text-decoration: none;
  padding: 0.75em 0.75em 0.3em 0.75em;
  color: #1d1d1d;
  font-size: 1em;
  font-family: 'Fjalla One', sans-serif;
  text-shadow: 4px 4px 4px #fefdfd;
}

.Nav ul {
  margin: 0;
  padding: 10px;
}

.Nav li a.active {
  color: #4688fe;
  border-bottom: 6px solid #4688fe;
}

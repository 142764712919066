.Donate {
  color: #1d1d1d;
  padding: 1em;
  align-self: flex-end;
}

.Donate-icon {
  color: #4688fe;
  margin-left: 20px;
  font-size: 1.2em;
}

.Donate form {
  display: inline-block;
}
